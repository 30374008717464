jQuery(function () {
  header();
  smooth();
  menuBtn();
  inviewElements();
  faq();
  parallax();

  $("#faq-list .first").click();
});

function header() {
  (function ($) {
    var menu = $("#menu");
    var header = $("#header");
    var showFlag = false;
    var positionCurrent = 0;

    $(window).scroll(function () {
      positionDiff = positionCurrent - $(window).scrollTop();
      positionCurrent = $(window).scrollTop();

      if (menu.hasClass("active")) {
        return;
      }

      if ($(this).scrollTop() > $("#header").outerHeight()) {
        if (!showFlag) {
          header.css({
            position: "fixed",
            top: -$("#header").outerHeight(),
          });
        }

        if (positionDiff > 0) {
          if (!showFlag) {
            header.stop(true).animate({ top: 0 }, 500, "easeOutExpo");
            showFlag = true;
          }
        } else {
          header.animate(
            { top: -$("#header").outerHeight() },
            300,
            "easeOutExpo",
            function () {
              showFlag = false;
            }
          );
        }
      } else if (header.scrollTop() >= $(window).scrollTop()) {
        header
          .css({
            position: "absolute",
            top: 0,
          })
          .stop(true)
          .css(
            {
              top: 0,
            },
            1
          );
      }
    });
  })(jQuery);
}

function smooth() {
  (function ($) {
    $('a[href^="#"]').click(function () {
      var speed = 800;
      var href = $(this).attr("href");
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top - $("#header").innerHeight() / 2;
      $("html, body").animate({ scrollTop: position }, speed, "easeOutExpo");
      return false;
    });
  })(jQuery);
}

function menuBtn() {
  var $menu = $("#menu");

  (function ($) {
    $("#menu-btn-wrap").click(function () {
      $(this).find("#menu-btn").toggleClass("active");
      return false;
    });

    $("#menu-btn-wrap").toggle(
      function () {
        $("html, body").css({ "overflow-y": "hidden" });
        $menu.fadeIn(200);
        $menu.addClass("active");
      },
      function () {
        $("html, body").css({ "overflow-y": "auto" });
        $menu.fadeOut(200);
        $menu.removeClass("active");
      }
    );

    $("#menu #menu-list li a").click(function () {
      $("#menu-btn-wrap").click();
    });
  })(jQuery);
}

function faq() {
  (function ($) {
    $(".q").on("click", function () {
      $(this).toggleClass("visible").next(".a").slideToggle(600, "easeOutExpo");
    });
  })(jQuery);
}

function inviewElements() {
  (function ($) {
    var BUFF_PER = 0.7;
    var ACTIVE_CLASS = "active";

    var $win = $(window);
    var $doc = $(document);
    var $elements = $(".inview");

    var scroll;
    var win_height;

    init();

    function init() {
      $win.on("load scroll resize", onScroll);
      onScroll();
    }

    function onScroll(e) {
      scroll = $win.scrollTop();
      win_height = $win.innerHeight();

      $elements.each(check);
    }

    function check(i, dom) {
      var $element = $elements.eq(i);
      var top = $element.offset().top;
      var height = $element.innerHeight();

      if (scroll > top - win_height * BUFF_PER && scroll < top + height) {
        $element.addClass(ACTIVE_CLASS).trigger("inview");
      }
    }
  })(jQuery);
}

function parallax() {
  (function ($) {
    var MIN_TRANSLATE_Y = (-20 / 150) * 100;
    var MAX_TRANSLATE_Y = (20 / 150) * 100;

    var $win = $(window);
    var $elements = $(".parallax");

    init();

    function init() {
      if (!$elements.length) {
        return;
      }

      $win.on("pageshow scroll resize", onScroll);
      $win.on("pageshow", onPageShow);
    }

    function onScroll() {
      var scroll = $win.scrollTop();
      var wh = $win.innerHeight();

      $elements.each(function (i, dom) {
        var $parallax = $elements.eq(i).find(".pic-parallax");

        var top = $elements.eq(i).offset().top;
        var h = $elements.eq(i).height();
        var start = top - wh;
        var end = top + h;
        var per = (scroll - start) / (end - start);
        var ty =
          (MIN_TRANSLATE_Y + (MAX_TRANSLATE_Y - MIN_TRANSLATE_Y) * per) * -1;

        $parallax.css({
          transform: "translateY(" + ty + "%)",
        });
      });
    }

    function onPageShow(e) {
      $elements.addClass("visible");
    }
  })(jQuery);
}
